import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../../scss/Header.scss';

function Header() {

  const [myLevel, setMyLevel] = React.useState(2);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myCookie, setMyCookie] = React.useState(true);

  const [myMenu, setMyMenu] = React.useState(false);

  const [myColorNav] = React.useState(false);

  useEffect(() => {

    if( localStorage.getItem('fm_cookie') && localStorage.getItem('fm_cookie') == 1 ) {
      setMyCookie(false);
    }

    if( localStorage.getItem("fm_info") ) {
      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      if( myCtrl ) {
        setMyLevel(userInfo.level);
      }

      setMyCtrl(false);
    }

  }, [myCtrl]);

  function logout(e) {
    e.preventDefault();
    localStorage.removeItem('fm_info');
    localStorage.removeItem('fm_session');
    window.location.href = "/";
  }

  function handleClose(e) {
    e.preventDefault();
    localStorage.setItem("fm_cookie", 1);
    setMyCookie(false);
  }

  function openMenu() {
    setMyMenu(!myMenu);
  }

  return (
    <div className="App">
      <div className="bannerCookie" style={myCookie ? { display: 'block' } : { display: 'none' } }>
        <p>
          I cookie ci permettono offrire i nostri servizi. Nell'utilizzare i
          nostri servizi, accetti l'uso che facciamo dei cookie.&nbsp;
          <Link to="/cookies" className="legalLink" target="_blank"  rel="noreferrer">
            Ulteriori informazioni
          </Link>&nbsp;&nbsp;&nbsp;
          <span className="closeButton" onClick={(e)=> {handleClose(e)}}>&times;</span>
        </p>
      </div>
      <header className="AppHeader">
        <div className="logo">
          <Link to="/">
            <img src="img/FantaUno-40.png" width="40" />
          </Link>
        </div>
        <div className="last itemsMenu">
            <a href="https://dashboard.fanta1.it" target="_blank" rel="noopener noreferrer">                
              Login
            </a>
        </div>
        <div className="last itemsMenuMobile">
          <a href="https://dashboard.fanta1.it" target="_blank" rel="noopener noreferrer">                
            Login
          </a>
        </div>
      </header>
    </div>
  );
}

export default Header;
