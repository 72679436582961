import React, { useEffect } from 'react';
import '../../scss/Cta.scss';
import { useTranslation } from "react-i18next";

function Cta() {

  const { t, i18n } = useTranslation('common');

  const [showCta, setShowCta] = React.useState(true);
  const [getLang, setLan] = React.useState("it");
  const [showCtaRegister, setShowCtaRegister] = React.useState(false);

  useEffect(() => {

    if( i18n.language != 'it-IT' ) {
      setLan("en");
    }

    if( !localStorage.getItem("fm_info") ) {
      setShowCtaRegister(true);
    }

    window.addEventListener("scroll", (event) => {
      if((window.innerHeight + window.scrollY) + 100 >= document.body.scrollHeight) {
          // you're at the bottom of the page
          setShowCta(false);
      }
      else if( window.scrollY > 400 ){
              setShowCta(true);
            }
      else {
        setShowCta(false);
      }

    });

  }, []);

  return (
    <div className="Cta" style={showCta ? { display: 'block' } : { display: 'none' } }>
      <div className="CtaItemsMenu">
        <div className="CtaItemMenu">
          <a href="https://play.google.com/store/apps/details?id=it.fanta1" target='_blank'>
            <img src={"img/google_store_" + getLang + ".png"} width="200" />
          </a>
        </div>
        <div className="CtaItemMenu CtaItemsMenuStyle" style={showCtaRegister ? { display: 'block' } : { display: 'none' } }>
          <a href="https://dashboard.fanta1.it" target="_blank" rel="noopener noreferrer">
              {t('register.title')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Cta;
