import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../../scss/Footer.scss';
import { useTranslation } from "react-i18next";

function Footer() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');
  const [getLang, setLan] = React.useState("it");
  const [showCtaRegister, setShowCtaRegister] = React.useState(false);


  useEffect(() => {
    if( i18n.language != 'it-IT' ) {
      setLan("en");
    }
    if( !localStorage.getItem("fm_info") ) {
      setShowCtaRegister(true);
    }
  }, []);

  return (
    <div className="Footer">

      <div className="Cta CtaFooter">
        <div className="CtaItemsMenu">
          <div className="CtaItemMenu" style={{ width: '200px' }}>
            <a href="https://play.google.com/store/apps/details?id=it.fanta1" target='_blank'>
              <img src={"img/google_store_" + getLang + ".png"} width="200" />
            </a>
          </div>
          <div className="CtaItemMenu CtaItemsMenuStyle" style={showCtaRegister ? { display: 'block' } : { display: 'none' } }>
            <a href="https://dashboard.fanta1.it" target="_blank" rel="noopener noreferrer">
                {t('register.title')}
            </a>
          </div>
        </div>
      </div>

      <div className="mobile">
        <a href="https://www.facebook.com/profile.php?id=100090841984906" target="_blank"  rel="noreferrer">
          <div className="social">
            <img src="img/facebook.png" alt="facebook" width="40" />
          </div>
        </a>
        <a href="https://www.instagram.com/fanta_one/" target="_blank"  rel="noreferrer">
          <div className="social">
            <img src="img/instagram.png" alt="instagram" width="40" />
          </div>
        </a>
      </div>
      <br />
      <div className="itemsMenu">
        <a href="https://www.facebook.com/profile.php?id=100090841984906" target="_blank"  rel="noreferrer" className="noMobile">
          <div className="social">
            <img src="img/facebook.png" alt="facebook" width="40" />
          </div>
        </a>
        <a href="https://www.instagram.com/fanta_one/" target="_blank"  rel="noreferrer" className="noMobile">
          <div className="social">
            <img src="img/instagram.png" alt="instagram" width="40" />
          </div>
        </a>
        <div>
          <Link to="/condizioni-legali">
              {t('home.legal')}
          </Link>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div>
          <Link to="/privacy-policy">
              Privacy Policy
          </Link>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div>
          <Link to="/cookies">
              Cookies
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
